<template>
  <div class="el-form-item form-item email is-success is-required">
    <label
      for="email"
      class="el-form-item__label"
    >Additional Email</label>
    <div class="el-form-item__content">
      <div class="el-input">
        <input
          v-model="email"
          type="text"
          autocomplete="off"
          placeholder="Email Address"
          class="el-input__inner"
        >
      </div>
    </div>
    <BaseButton
      class="save-btn"
      type="cta"
      @click="saveNotification"
    >
      Save Notification
    </BaseButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  name: 'AdditionalEmailAdd',
  components: {
    BaseButton,
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters(['getOrgId']),
  },
  methods: {
    ...mapActions({ updateEmailForNotifications: 'updateEmailForNotifications' }),

    async saveNotification() {
      const payload = {
        orgId: this.getOrgId,
        groupId: '',
        email: this.email,
        userId: '',
      };
      this.$emit('save-user');
      await this.updateEmailForNotifications(payload);
      this.$emit('sort-user');
      this.email = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.save-btn {
  margin-top: 25px;
  width: 100%;
}
</style>
