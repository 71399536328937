<template>
  <LayoutCentered>
    <BaseHeader type="h1">
      Settings for Practice Builder
    </BaseHeader>

    <div class="admin-pannel-link">
      <!-- <router-link
        to="/dashboard/admin/locations">
        <i class="el-icon-back back-panel-label" /><label class="back-panel-label">back to admin panel</label>
      </router-link> -->
    </div>

    <el-tabs tab-position="top">
      <el-tab-pane label="Branding">
        <LocationBrand />
      </el-tab-pane>

      <el-tab-pane label="Notifications">
        <NotficationUserView />
      </el-tab-pane>
    </el-tabs>
  </LayoutCentered>
</template>
<script>

import LocationBrand from '@/components/dashboard/LocationBrand.vue';
import LayoutCentered from '@/components/common/LayoutCentered.vue';
import BaseHeader from '@/components/common/BaseHeader.vue';
import NotficationUserView from '@/components/practice-builder/notification/NotificationUserView.vue';

export default {
  name: 'Settings',
  components: {
    LocationBrand,
    LayoutCentered,
    BaseHeader,
    NotficationUserView,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 625px) {
.admin-pannel-link{
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    transform: translateX(658%);
  }
}

.back_button{
    float: right;
  }
.back-panel-label {
    color: #f05a28;
    cursor: pointer;
}
</style>
