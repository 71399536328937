<template>
  <div @click="$emit('user-click')">
    <div :class="{ selected: user.isSelected }" class="user-container">
      <div class="user-preview">
        <div class="user-icon">
          <AvatarLetter :user="user" :letter="nameFirstLetter" />
        </div>
        <div class="user-contact-info">
          <p class="user-name">{{ user.firstName }} {{ user.lastName }}</p>
          <p class="user-email">
            {{ user.email }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.js"></script>
<script>
import UserDetails from "@/components/dashboard/org-user-management/components/UserDetails.vue";
import AvatarLetter from "@/components/common/AvatarLetter.vue";
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "NotificationUsers",
  components: {
    UserDetails,
    BaseButton,
    AvatarLetter,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewSettings: false,
      hovered: false,
      disableUser: false,
    };
  },
  computed: {
    isDirectoryUser() {
      let value = false;
      this.user.organizationPermissions.forEach((permission) => {
        if (permission.name === "OrgDirectory") {
          if (permission.value === 2) {
            value = true;
          }
        } else {
          value = false;
        }
      });
      return value;
    },
    nameFirstLetter() {
      if (this.user.firstName) {
        return this.user.firstName.charAt(0).toUpperCase();
      } else {
        return this.user.email.charAt(0).toUpperCase();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-tag {
  margin-left: 10px;
}
.directory-tag {
  margin-right: 10px;
}
.selected {
  box-shadow: -5px 5px 10px 0px #a0a0aa33;
  border: 1px solid #f05a28 !important;
}
.user-container {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 0.5s ease;
  margin-left: 10px;
  width: 274px;
  height: 60px;
  cursor: pointer;
}

.user-preview {
  display: flex;
  align-items: center;
  .user-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .user-profile {
    padding-right: 15px;
    border-right: 1px solid #e6e6e6;
    margin-right: 20px;
  }
  .user-contact-info {
    width: 210px;
    // padding-right: 15px;
    // border-right: 1px solid #e6e6e6;
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-name {
      font-weight: 600;
      color: $dark-blue;
    }
    .user-email {
      color: #666;
      font-size: 12px;
    }
  }
  .business-count {
    p {
      margin: 0;
      font-size: 14px;
      color: #6b97b9;
    }
    margin-left: 15px;
  }
  .edit-location-button {
    margin-left: 25px;
  }
  .user-type {
    text-transform: uppercase;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 25px;
    align-items: center;
  }
  .options {
    color: #666;
    margin-left: auto;
    transition: all 0.2s ease;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    &:hover {
      cursor: pointer;
      background-color: #feefea;
      color: $orange;
    }
  }

  .result {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
  }

  .content {
    display: flex;
  }

  /*checkbox styleing */
  .label-checkbox {
    margin-right: 0.87rem;
    margin-left: auto;
    border: 1px solid #4273de;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4273de;
  }

  .check-day {
    visibility: hidden;
    position: absolute;
    right: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .checked {
    background: #4273de;
    color: #fff;
  }

  .checked::before {
    content: "✔";
  }
  /*end */
}
</style>
