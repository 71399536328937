<template>
  <div
    v-loading="loading"
    class="store-container"
  >
    <template>
      <div class="card">
        <div class="right">
          <!-- <BaseButton
            class="back_button"
            icon="el-icon-back">
            Back
          </BaseButton> -->
        </div>
        <p class="header">
          Notification
        </p>

        <div class="notification-user">
          <NotificationUsers
            v-for="(user, index) in allNotifyUsers"
            :key="index"
            :user="user"
            @user-click="selectUser(user)"
          />
        </div>
        <div>
          <AdditionalEmailAdd
            @save-user="saveUserForNotification"
            @sort-user="sortSelectedUser"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

// import BaseButton from '@/components/common/BaseButton.vue';
import NotificationUsers from '@/components/practice-builder/notification/NotificationUsers.vue';
import AdditionalEmailAdd from '@/components/practice-builder/notification/AdditonalEmailAdd.vue';

export default {
  name: 'NotficationUserView',
  components: {
    // BaseButton,
    NotificationUsers,
    AdditionalEmailAdd,
  },
  props: {},
  data() {
    return {
      input: '',
      addUser: false,
      allNotifyUsers: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getOrgId', 'getNotificationsUsers']),
    ...mapGetters("accountsModule", ["getOrgDosingDetail", "getOrgUsers"]),
    filteredUsers() {
      return this.getOrgUsers.filter((user) => {
        return user.firstName.toLowerCase().indexOf(this.input.toLowerCase()) !== -1;
      });
    },
    currentContactImages() {
      return this.getContactImages.map((image) => {
        const contactImage = {};
        contactImage.isSelected = image.isSelected;
        contactImage.isDefault = image.isDefault;
        contactImage.url = `${image.location}`;
        return contactImage;
      });
    },
  },
  async created() {
    const payload = {
      orgId: this.getOrgId,
      groupId: '',
    };
    await this.fetchAllUserForNotifications(payload);
    this.sortSelectedUser();
  },
  methods: {
    ...mapActions({ removeUserFromNotification: 'removeUserFromNotification', updateEmailForNotifications: 'updateEmailForNotifications', fetchAllUserForNotifications: 'fetchAllUserForNotifications' }),
    toggleUserCreation() {
      this.addUser = !this.addUser;
      this.addUserStep = 0;
    },
    saveUserForNotification() {
      this.loading = !this.loading;
    },
    sortSelectedUser() {
      this.allNotifyUsers = [];
      const activeUsers = this.getOrgUsers.filter((user) => {
        return user.active;
      });

      activeUsers.forEach((data) => {
        const user = {};
        user.firstName = data.firstName;
        user.lastName = data.lastName;
        user.email = data.email;
        user.userId = data.userId;
        const notifyUsers = this.getNotificationsUsers.filter((notifyUser) => {
          return user.email === notifyUser.email;
        });
        user.isSelected = notifyUsers.length > 0;
        user.resId = notifyUsers.length > 0 ? notifyUsers[0].id : '';
        this.allNotifyUsers.push(user);
      });
      const otherUsers = this.getNotificationsUsers.filter((user) => {
        return !user.userId;
      });
      otherUsers.forEach((data) => {
        const user = {};
        user.email = data.email;
        user.resId = data.id;
        user.isSelected = true;
        this.allNotifyUsers.push(user);
      });
      this.loading = false;
    },
    async selectUser(user) {
      this.loading = true;
      if (user.resId) {
        const payload = {
          orgId: this.getOrgId,
          orgEmailId: user.resId,
        };
        await this.removeUserFromNotification(payload);
      } else {
        const payload = {
          orgId: this.getOrgId,
          groupId: '',
          email: user.email,
          userId: user.id,
        };
        await this.updateEmailForNotifications(payload);
      }
      this.sortSelectedUser();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  padding: 25px;
  background-color: #fff;
  height: min-content;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  margin: 5px;
  margin-bottom: 25px;
  .header {
    display: flex;
    padding-bottom: 25px;
    border-bottom: 1px solid #e6e6e6;
    align-items: center;
    height: 60px;
    font-weight: 600;
    font-size: 18px;
    p {
      font-weight: 600;
      color: #374458;
      letter-spacing: 1px;
    }
    .card-action {
      margin-left: auto;
      height: 100%;
      display: flex;
    }
  }
  .name {
    font-weight: 600;
    color: $dark-blue;
  }
  .address {
    line-height: 12px;
    font-size: 14px;
  }
  .back_button {
    float: right;
  }
  .notification-user {
    display: flex;
    flex-wrap: wrap;
  }

}
</style>
